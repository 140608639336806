import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import './App.css';
import octopus from './img/octopus.png'
import project1 from './img/project1.png'
import project2 from './img/project2.png'
import project3 from './img/project3.png'
import { Routes, Route, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react';
import AboutTypingAnimation from './typing/AboutTyping';
import ProjectTypingAnimation from './typing/ProjectTyping';
import ContactTypingAnimation from './typing/ContactTyping';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import {SectionsContainer, Section, Header} from 'react-fullpage';
import ProjectListPage from './ProjectList';
import Alert from 'react-bootstrap/Alert';

function App() {

  let navigate = useNavigate()

  let options = {
    anchors: ['sectionOne', 'sectionTwo', 'sectionThree', 'sectionFour'],
    scrollBar:            false,
    navigation:           true,
    verticalAlign:        false,
    arrowNavigation:      true
  };

  const snsGroupRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const snsGroup = snsGroupRef.current;
      if (!snsGroup) return;

      const top = snsGroup.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight) {
        const icons = snsGroup.querySelectorAll('.sns-button');
        icons.forEach((icon, index) => {
          icon.style.animationDelay = `${0.1 * (index + 1)}s`;
          icon.classList.add('animate');
        });

        // Scroll 이벤트 리스너 제거
        window.removeEventListener('scroll', handleScroll);
      }
    };

    // Scroll 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 클리어
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.animate-up');
      elements.forEach(el => {
        const rect = el.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          el.classList.add('in-view');
        }
      });
    };

    handleScroll(); // 초기 로드시에도 한번 실행
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [activeSection, setActiveSection] = useState('home'); // 기본적으로 홈 섹션이 활성화되도록 설정

  // 섹션의 위치 정보를 저장할 객체
  const sectionPositions = {};

  useEffect(() => {
    const handleScroll = () => {
      // 현재 스크롤 위치
      const scrollPosition = window.scrollY + 100; // 네비게이션바가 고정되어 있어서 추가

      // 각 섹션의 위치와 높이를 확인하고 활성 섹션을 결정
      for (const section in sectionPositions) {
        const { top, height } = sectionPositions[section];
        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveSection(section);
          break;
        }
      }
    };

    // 섹션의 위치 정보를 설정
    const setSectionPositions = () => {
      const sections = document.querySelectorAll('section[id]');
      sections.forEach(section => {
        sectionPositions[section.id] = {
          top: section.offsetTop,
          height: section.offsetHeight
        };
      });
    };

    // 초기화
    setSectionPositions();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', setSectionPositions);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', setSectionPositions);
    };
  }, []);

  return (
    <div className="App" style={{fontFamily:"WavvePADO-Regular"}}>
      <Routes>
        <Route path="/" element={
          <div>
            <Header>
              <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky='top' data-bs-theme="dark">
                <Container>
                  <Navbar.Brand href="https://devlwh.com">전설의 문어</Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                      <Nav.Link onClick={()=>{ navigate('/') }}>MAIN</Nav.Link>
                      <Nav.Link onClick={()=>{ navigate('/projects') }}>PROJECT</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Header>
            <SectionsContainer {...options}>
              <Section>
                <section id="home" className='section'>
                  <div className='main'>
                    <p className="mount1">꿈은 있지만</p>
                    <p className="mount2">놀고싶습니다.</p>
                    <div className="typing-animation">
                      <p className="typing-text">
                        I have dreams, but I want to have fun. 😝
                      </p>
                    </div>
                  </div>
                </section>
              </Section>
              <Section>
                <section id="about" className='section'>
                  <div className='contents'>
                    <AboutTypingAnimation/>
                    <Container className="my-auto">
                      <Nav className="justify-content-center">
                        <Nav.Item style={{marginRight:"20px"}}>
                          <Image src={octopus} roundedCircle />
                        </Nav.Item>
                        <Nav.Item>
                          <div className="introduce text-center">
                            <h1>안녕하세요. <b style={{ color: "#61dafb" }}>이원희</b>라고 합니다.</h1>
                            <p>
                              저는 <b style={{ color: "#61dafb" }}>"전설의 문어"</b>라는 닉네임으로 개발을 좋아하는 <b style={{ color: "#61dafb" }}>평범한 대학생</b> 입니다.
                              <br />
                              앱 개발을 좋아하며 다양한 <a href='#'>프로젝트</a>를 진행하고 있습니다.
                            </p>
                          </div>
                        </Nav.Item>
                      </Nav>
                    </Container>
                    <Container className="animate-up" style={{marginTop:"40px"}}>
                      <Accordion data-bs-theme="dark">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>🥳 생일</Accordion.Header>
                          <Accordion.Body style={{ fontSize: "20px" }}>
                            저의 생일은 <b style={{ color: "skyblue" }}>8월 30일</b>이에요.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>😝 MBTI</Accordion.Header>
                          <Accordion.Body style={{ fontSize: "20px" }}>
                            저의 MBTI는 <b style={{ color: "skyblue" }}>ISTJ</b>이에요.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>🖥️ 사용가능한 기술스택</Accordion.Header>
                          <Accordion.Body style={{ fontSize: "20px" }}>
                            저는 <b style={{ color: "skyblue" }}>Python, Java, Flutter, AWS</b>를 쓸 수 있어요.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Container>
                  </div>
                </section>
              </Section>
              <Section>
                <section id='project' className='section'>
                  <div>
                    <ProjectTypingAnimation/>
                    <Container style={{ borderRadius: "30px", overflow: "hidden" ,marginTop:"20px", width:"120vh"}} className="mobile-size">
                      <Carousel data-bs-theme="dark" fade={true} interval={5000}>
                        <Carousel.Item>
                          <div style={{ borderRadius: "30px", overflow: "hidden" }}>
                            <img
                              className="d-block w-100"
                              src={project3}
                              alt="First slide"
                              style={{ borderRadius: "30px" }}
                            />
                          </div>
                          <Carousel.Caption>
                            <div style={{ borderRadius: "30px", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "1px" ,paddingTop:"15px"}}  className='mobile-text'>
                              <h5 style={{ color: "white" }}>팀 이노빅스</h5>
                              <p style={{ color: "white" }}>제가 리더로 활동하고 있는 서비스 개발 팀 입니다.</p>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div style={{ borderRadius: "30px", overflow: "hidden" }}>
                            <img
                              className="d-block w-100"
                              src={project1}
                              alt="Second slide"
                              style={{ borderRadius: "30px" }}
                            />
                          </div>
                          <Carousel.Caption>
                            <div style={{ borderRadius: "30px", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "1px" ,paddingTop:"15px"}}  className='mobile-text'>
                              <h5 style={{ color: "white" }}>학생비서 프로젝트</h5>
                              <p style={{ color: "white" }}>초중고 학생을 위한 디스코드 봇 프로젝트 입니다.</p>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div style={{ borderRadius: "30px", overflow: "hidden" }}>
                            <img
                              className="d-block w-100"
                              src={project2}
                              alt="Third slide"
                              style={{ borderRadius: "30px" }}
                            />
                          </div>
                          <Carousel.Caption>
                            <div style={{ borderRadius: "30px", backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "1px" ,paddingTop:"15px" }}  className='mobile-text'> 
                              <h5 style={{ color: "white" }}>버스타자, 버타</h5>
                              <p style={{ color: "white" }}>Flutter를 이용한 첫 앱 개발 프로젝트 입니다.</p>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Container>
                    <Button style={{marginTop:"30px"}} onClick={()=>{
                      navigate('/projects')
                    }} variant="outline-light">프로젝트 리스트</Button>{' '}
                  </div>
                </section>
              </Section>
              <Section id='contact' className='section'>
                <section id='contact' className='section'>
                  <div className='mobile-section'>
                    <div className='mobile-title'>
                      <ContactTypingAnimation/>
                    </div>
                    <Container ref={snsGroupRef}>
                      <ul className="sns-group">
                        <li className="sns-button">
                          <a href="https://github.com/devlwh0830">
                            <i className="fab fa-github" aria-hidden="True"></i>
                            <div className='tooltip'>
                              <span className="tooltiptext tooltip-bottom">깃허브</span>
                            </div>
                          </a>
                        </li>
                        <li className="sns-button">
                          <a>
                            <i className="fab fa-discord" aria-hidden="True"></i>
                            <div className='tooltip'>
                              <span className="tooltiptext tooltip-bottom">legendary_octopus_0830</span>
                            </div>
                          </a>
                        </li>
                        <li className="sns-button">
                          <a href="https://www.instagram.com/dev_wonhee/">
                            <i className="fab fa-instagram" aria-hidden="True"></i>
                            <div className='tooltip'>
                              <span className="tooltiptext tooltip-bottom">인스타그램</span>
                            </div>
                          </a>
                        </li>
                        <li className="sns-button">
                          <a href="https://velog.io/@story3798/posts">
                            <i className="fab fa-font-awesome" aria-hidden="true"></i>
                            <div className='tooltip'>
                              <span className="tooltiptext tooltip-bottom">기술블로그</span>
                            </div>
                          </a>
                        </li>
                        <li className="sns-button">
                          <a href="https://teaminnovix.com">
                            <i className="fa-solid fa-house" aria-hidden="true"></i>
                            <div className='tooltip'>
                              <span className="tooltiptext tooltip-bottom">팀 이노빅스</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </Container>
                  </div>
                </section>
              </Section>
            </SectionsContainer>
          </div>
        } />
        <Route path="/projects" element={
          <ProjectListPage/>
        } />
        <Route path="*" element={
          <div className='error'>
            <Alert variant="danger">
              <Alert.Heading>페이지를 찾을 수 없습니다.</Alert.Heading>
              <p>
                요청하신 페이지를 찾을 수 없습니다. 다시 한번 확인해 주세요.
              </p>
              <hr />
              <p className="mb-0">
                문제가 지속되면 관리자에게 문의하세요.
              </p>
            </Alert>
            <Button style={{marginTop:"30px"}} onClick={()=>{
              navigate('/')
            }} variant="outline-light">메인으로 돌아가기</Button>{' '}
          </div>
        } />
      </Routes>
    </div>
  );
}

export default App;