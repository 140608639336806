import React, { useState, useEffect, useRef } from 'react';

const TypingAnimation = () => {
  const [text, setText] = useState('');
  const fullText = "Who am I? 🤔";
  const aboutSectionRef = useRef(null); // about 섹션을 참조하기 위한 useRef

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // about 섹션이 화면에 50% 이상 보일 때 작동
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          startTypingAnimation(); // about 섹션이 화면에 진입하면 타이핑 애니메이션 시작
          observer.unobserve(entry.target); // 한 번만 작동하도록 관찰 해제
        }
      });
    }, options);

    if (aboutSectionRef.current) {
      observer.observe(aboutSectionRef.current); // about 섹션을 관찰
    }

    return () => {
      if (aboutSectionRef.current) {
        observer.unobserve(aboutSectionRef.current); // 컴포넌트가 언마운트될 때 관찰 해제
      }
    };
  }, []); // 빈 배열을 전달하여 componentDidMount 시점에서 한 번만 실행되도록 설정

  const startTypingAnimation = () => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100); // 글자가 추가되는 속도를 조정할 수 있습니다. (단위: 밀리초)
  };

  return (
    <div>
      {/* about 섹션을 참조할 ref */}
      <section ref={aboutSectionRef}>
        <p>{text}</p>
      </section>
    </div>
  );
}

export default TypingAnimation;
